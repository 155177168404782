import * as React from "react"
import PropTypes from "prop-types"
const Layout = ({ children }) => {

  return (
    <>
        <main className="max-w-6xl mx-auto pb-96">
          {children}
        </main>
    </>
  )
}
Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
