import React, { useState, useEffect } from "react"
import Seo from "../components/seo"
import spinner from "../components/spinner"

import CheckCircleOutlineIcon from '@atlaskit/icon/glyph/check-circle-outline'
const PdfTemplate = ({isOps, title, data, promoTemplate, type, objective, property}) => {
    // ----------------------
    // RUNTIME DATA FETCHING
    // ----------------------..
    const [formGeneratorData, setFormGeneratorData] = useState(spinner)
    useEffect(() => {
      const queryParams = new URLSearchParams(window.location.search);
      const postSlug = queryParams.get('slug');
      let fetchUrl = data

        Array.prototype.sample = function(){
          return this[Math.floor(Math.random()*this.length)];
        }
        const loremIpsum = [
          'Excepteur sint occaecat cupidatat non proident, sunt in.',
          'Culpa qui officia deserunt mollit anim id est laborum.',
          'Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipi.',
          'Sed ut perspiciatis unde omnis iste natus erro.',
          'Uis autem vel eum iure reprehenderit qui in end.',
          'Sunt in culpa qui officia deserunt mollit anim id est laborum.',
          'Sed ut perspiciatis unde omni.',
          'Uis autem vel eum iure reprehenderi.',
          'Vel eum iure reprehenderi.'
        ]

        fetch(fetchUrl)
        .then(response => response.json()) // parse JSON from request
        .then(resultData => {
            setFormGeneratorData(
                resultData.map((item, index) => {
                    return  (
                        <div className={
                          item.layout.includes('indent') === true || item.options.includes('altIndent') === true ? 'relative pl-8 ' :
                          item.options.includes('hideFromPdf') ? 'hidden' : 'relative' }>

                          {item.type === "textarea" || item.type === "text" || item.type === "dropdown" ?

                            <div style={{ padding: '0 0 1rem 0'}}>
                              <p className="font-bold">{item.title}</p>
                              <p>
                                <em className={"block p-4 py-2 font-normal " + (item.type === "textarea" ? "w-full displayBlock " : "")}>{loremIpsum.sample()}
                                </em>
                              </p>
                            </div>

                          : item.type === "checkbox" ?

                            <div style={{ overflow: 'hidden' }}>
                              <div style={{ float: 'left', width: '24px' }}>{item.theme === "noIcon" ? null : <CheckCircleOutlineIcon />}</div>
                              <div style={{ margin: '0 0 0 34px'}}>
                                <div dangerouslySetInnerHTML={{__html: item.title
                                  .replaceAll('[text]', '<em>'+Math.floor(Math.random() * 10)+'</em>')
                                  .replaceAll('[ text ]', '<em>'+Math.floor(Math.random() * 10)+'</em>')
                                  .replaceAll('[inline]', '<em>'+Math.floor(Math.random() * 10)+'</em>')
                                  .replaceAll('[date]', '<em>June 5, 2022</em>')
                                  .replaceAll('[time]', '<em>5:30 PM</em>')
                                }}></div>
                              </div>
                            </div>

                          : item.type === "rowOneInput" ?

                            <div style={{ overflow: 'hidden' }}>
                              <div style={{ float: 'left', width: "30%"}}>
                                <div style={{ overflow: 'hidden'}}>
                                  <div style={{ float: 'left', width: '24px' }}><CheckCircleOutlineIcon /></div>
                                  <p style={{ margin: '0 0 0 34px'}}>
                                    <div dangerouslySetInnerHTML={{__html: item.title
                                      .replaceAll('[text]', '<em>'+Math.floor(Math.random() * 10 + 2)+'</em>')
                                      .replaceAll('[ text ]', '<em>'+Math.floor(Math.random() * 10 + 2)+'</em>')
                                      .replaceAll('[inline]', '<em>'+Math.floor(Math.random() * 10 + 2)+'</em>')
                                      .replaceAll('[date]', '<em>June 5, 2022</em>')
                                      .replaceAll('[time]', '<em>5:30 PM</em>')
                                    }}></div>
                                  </p>
                                </div>
                                {item.options === "addQuantity" ? <p className="w-full pl-8 text-sm">Quantity: <em>3</em> </p> : null}
                              </div>
                              <div style={{ float: 'right', width: '68%'}}> <em className="w-full displayBlock">{loremIpsum.sample()}</em></div>
                            </div>


                          : item.type === "rowTwoInputs" ?

                            <>
                              {item.options === 'addPrizeWinnerAmtHeader' ?
                                <>
                                  <div style={{ overflow: 'hidden', padding: '1rem 0 0 0' }}>
                                    <div style={{ float: 'left', width: '33%', fontWeight: 'bold', padding: '0 0 2rem 0 '}}>Prize</div>
                                    <div style={{ float: 'left', width: '33%', fontWeight: 'bold', padding: '0 0 2rem 0 '}}># of winners</div>
                                    <div style={{ float: 'left', width: '33%', fontWeight: 'bold', padding: '0 0 2rem 0 '}}>Amount(s)</div>
                                  </div>
                                </>
                              : null }
                              <div style={{ overflow: 'hidden' }}>
                                <div style={{ float: 'left', width: '33%', padding: '0 0 2rem 0 '}}>
                                  <div style={{ float: 'left', width: '24px' }}><CheckCircleOutlineIcon /></div>
                                  <div style={{ margin: '0 0 0 34px'}}>
                                    <div dangerouslySetInnerHTML={{__html: item.title
                                      .replaceAll('[text]', '<em className="inline">1</em>')
                                      .replaceAll('[inline]', '<em className="inline">1</em>')
                                      }}/>
                                  </div>
                                </div>
                                <div style={{ float: 'left', width: '33%', padding: '0 0 .5rem 1% '}}>
                                  <em style={{ display: 'block', height: '100%'}}>{loremIpsum.sample()}</em></div>
                                <div style={{ float: 'left', width: '33%', padding: '0 0 .5rem 1% ' }}>
                                  <em style={{ display: 'block', height: '100%'}}>{loremIpsum.sample()}</em></div>
                              </div>
                            </>

                          : item.type === "h2" || item.type === "h3" ?

                              <>
                              {item.options === 'hideFromPdf' ? null :
                                <div className={item.layout}>
                                  <p className="font-bold ">{item.title}</p>
                                </div>
                              }
                              </>

                          : item.type === "h1" ?

                              <>
                                <hr/>
                                <p className="pt-4 text-xl font-bold border-t text-primary">{item.title}</p>

                                {item.options === "addDateRangeOfPromotion" ?
                                  <>
                                    <div style={{ overflow: 'hidden', padding: '1rem 0 0 0', width: '100%' }}>
                                      <div style={{ width: '33%', float: 'left', fontWeight: 'bold' }}>DATE RANGE OF PROMOTION:</div>
                                      <div style={{ width: '33%', float: 'left', fontWeight: 'bold' }}>Start</div>
                                      <div style={{ width: '33%', float: 'left', fontWeight: 'bold' }}>End</div>
                                    </div>
                                    <div style={{ overflow: 'hidden', padding: '1rem 0 0 0', width: '100%' }}>
                                      <div style={{ width: '33%', float: 'left' }}>Dates</div>
                                      <div style={{ width: '33%', float: 'left' }}><em className="displayBlock" style={{ display: 'block', margin: '0 .5rem 0 0'}}>05/16/2022</em></div>
                                      <div style={{ width: '33%', float: 'left' }}><em className="displayBlock" style={{ display: 'block', margin: '0 .5rem 0 0'}}>05/24/2022</em></div>
                                    </div>
                                    <div style={{ overflow: 'hidden', padding: '1rem 0 0 0', width: '100%' }}>
                                      <div style={{ width: '33%', float: 'left' }}>Times</div>
                                      <div style={{ width: '33%', float: 'left' }}><em className="displayBlock" style={{ display: 'block', margin: '0 .5rem 0 0'}}>05:00 PM</em></div>
                                      <div style={{ width: '33%', float: 'left' }}><em className="displayBlock" style={{ display: 'block', margin: '0 .5rem 0 0'}}>11:00 PM</em></div>
                                    </div>
                                  </>
                                : null}
                              </>

                          : item.type === "p" ?

                              <>
                                <p className="pt-4" dangerouslySetInnerHTML={{__html: item.title
                                  .replaceAll('[text]', '<em>'+Math.floor(Math.random() * 10)+'</em>')
                                  .replaceAll('[ text ]', '<em>'+Math.floor(Math.random() * 10)+'</em>')
                                  .replaceAll('[inline]', '<em>'+Math.floor(Math.random() * 10)+'</em>')
                                  .replaceAll('[date]', '<em>June 5, 2022</em>')
                                  .replaceAll('[time]', '<em>5:30 PM</em>')
                                }}/>
                              </>

                          : item.type === "html" ?

                              <>
                                <div className="prose" dangerouslySetInnerHTML={{__html: item.title }}></div>
                              </>

                          : item.type === "atDateAndTime" ?

                              <>
                                <div style={{ overflow: 'hidden', width: '500px', textAlign: 'center' }}>
                                  <div style={{ width: '33%', float: 'left' }}><em style={{ display: 'block'}}>04/13/2022</em></div>
                                  <div style={{ width: '10%', float: 'left', padding: '.2rem 0 0 0' }}>at</div>
                                  <div style={{ width: '33%', float: 'left' }}><em style={{ display: 'block'}}>08:00 PM</em></div>
                                </div>
                              </>

                          : item.type === "checkboxes" ?

                              <div className="prose"><p><em className="block p-4 py-2 font-normal">{item.options.replaceAll(' | ', ', ')}</em></p></div>

                          : item.type === "fileUpload" || item.type === "button" ?
                                null
                          :
                            <p>{item.title}</p>
                          }
                        </div>
                    )
                })
            )
        }) // set data for the Form
    }, [])
    return (
      <>
        <Seo title="Home" />
        <div className="flex flex-col p-16 bg-white border-2 border-b-2 shadow-lg content-area gap-y-4 " id="pdf">
          <div className="header"
            style={{ overflow: 'hidden', padding: '1rem 0', borderBottom: '1px solid #000' }}>
              <div
                style={{ float: 'left', width: '50%' }}>
                  <div style={{ float: 'left', width: '48%' }}>
                      <div className="text-base font-semibold text-primary">PROPERTY</div>
                      <div className="text-4xl font-bold ">Atmore</div>
                  </div>
                  <div style={{ float: 'right', width: '48%' }}>
                      <div className="pb-2 text-base font-semibold text-primary">OPS ID</div>
                      <div className="text-2xl font-bold">WCA-APRIL-2022</div>
                  </div>
              </div>
              <div style={{ float: 'right', width: '25%' }}>
                  <img src="https://hello.archermalmo.com/wc/public/static/ade30fcf6938ffc3ce6ee348f310ab4e/6c533/wc_logo.png" className="w-[250px]" alt="Logo"/>
              </div>
          </div>

        {isOps ? null :
          <table className="">
            <tr className="p-2 text-white border bg-primary border-primary">
              <td className="w-3/12 p-3 ">Promotion Template</td>
              <td className="w-2/12 p-3 ">Type</td>
              <td className="w-3/12 p-3 ">Objective</td>
              <td className="w-4/12 p-3 ">Property</td>
              <td className="w-4/12 p-3 ">Promo&nbsp;ID</td>
            </tr>
            <tr className="p-1">
              <td className="p-3 border border-primary">{promoTemplate}</td>
              <td className="p-3 border border-primary">{type}</td>
              <td className="p-3 border border-primary">{objective}</td>
              <td className="p-3 border border-primary">
                <div className="overflow-hidden "> {property} </div>
              </td>
              <td className="p-3 border border-primary">{objective}</td>
            </tr>
          </table>
        }

          <div className="mt-4 text-2xl font-bold">{title}</div>
          {isOps ?
            <div>
                <div class="text-xs font-semibold text-color-wc400">PROMO ID</div>
                <div class="text-base ">WCA-SUPERSENIORS-04-2022</div>
                <div class="text-xs">Last Saved: 01-01-2022 2:38:42 PM</div>
            </div>
          :
            <div>
              <div className="text-xs">Last Saved: 01-01-2022 2:38:42 PM</div>
            </div>
          }
          {formGeneratorData}
        </div>

        {/* Whitelist dynamic classes: */}
        <div className="py-4 md:w-1/2 md:w-1/4"></div>

      </>
    )
};
export default PdfTemplate
